<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33333 11.332H16M9.33333 15.9981H20M9.33333 23.9972V27.1108C9.33333 27.8212 9.33333 28.1764 9.47897 28.3589C9.60563 28.5175 9.79769 28.6098 10.0007 28.6096C10.2342 28.6093 10.5116 28.3875 11.0663 27.9437L14.247 25.3995C14.8967 24.8798 15.2216 24.6199 15.5833 24.4351C15.9043 24.2711 16.2459 24.1513 16.599 24.0789C16.9969 23.9972 17.4129 23.9972 18.245 23.9972H21.6C23.8402 23.9972 24.9603 23.9972 25.816 23.5613C26.5686 23.1778 27.1805 22.566 27.564 21.8134C28 20.9579 28 19.8379 28 17.5979V10.3988C28 8.15882 28 7.03885 27.564 6.1833C27.1805 5.43074 26.5686 4.81888 25.816 4.43544C24.9603 3.99951 23.8402 3.99951 21.6 3.99951H10.4C8.15979 3.99951 7.03969 3.99951 6.18404 4.43544C5.43139 4.81888 4.81947 5.43074 4.43597 6.1833C4 7.03885 4 8.15882 4 10.3988V18.6645C4 19.9043 4 20.5242 4.1363 21.0328C4.50617 22.413 5.58436 23.4911 6.96472 23.8609C7.47339 23.9972 8.09337 23.9972 9.33333 23.9972Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconMessageTextSquare"
}
</script>
